import React, { useEffect, useRef, useState } from "react";
import Header from "../../include/header";
import Footer from "../../include/footer";
import { apiRequest } from "../../../config/apiRequest";
import { API_END_URLS } from "../../../config/endUrls";
import { showMessage } from "../../../helpers/toast";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { S3Bucket, _BlogCategory } from "../../../config/constants";
import { setGlobalState } from "../../../redux/dispatch";
import { _HomePages } from "../../../config/constants";
import FooterBottam from "../../../components/FooterBottam/FooterBottam";
import { getCredentials } from "../../../helpers/auth";
import { Modal } from "react-bootstrap";
import VideoSlider from "./VideoSlider";

var sliderfirstsetting2 = {
  dots: false,
  arrows: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  initialSlide: 0,
};

function HomeNew() {
  var sliderfirstsetting = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    initialSlide: 0,
  };
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const fatchData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getCMSRecords,
      method: "get",
    });
    setGlobalState({ loader: true });
    if (success) {
      let filter = data.filter((module) => module.module === "home_page");
      setData(filter);
    } else {
      showMessage(message);
      setGlobalState({ loader: false });
    }
    setGlobalState({ loader: false });
  };

  /* getAll Category */
  const [category, setCategory] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const firstMatchRef = useRef(null);

  const getAllCategoryList = async () => {
    try {
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.allCategories,
        method: "get",
      });
      setGlobalState({ loader: true });
      if (success) {
        setCategory(data);
      } else {
        showMessage(message);
      }
    } catch (err) {
      console.log("🚀 ~ file: index.js:56 ~ getAllCategoryList ~ err:", err);
      setGlobalState({ loader: false });
    }
    setGlobalState({ loader: false });
  };

  /* Range Of Products Function  Start Code*/
  const [rangeOfProduct, setRangeOfProduct] = useState([]);

  const rangeProduct = async (req, res) => {
    try {
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getRangeOfProuct,
        method: "get",
      });
      setGlobalState({ loader: true });
      if (success) {
        setRangeOfProduct(data);
      } else {
        showMessage(message);
        setGlobalState({ loader: false });
      }
    } catch (err) {}
    setGlobalState({ loader: false });
  };

  /* Range Of Products Function  End Code*/

  /* Review And Rating List Start Code */

  const [reviewData, SetReviewData] = useState([]);

  const fetchReviewData = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.reviewsAll,
      method: "get",
    });
    if (success) {
      SetReviewData(data.review.rows);
    } else {
      showMessage(message);
    }
  };

  /* Review And Rating List End Code */

  /* Advertisement Start Code */
  const [advertisementBanner, setAdvertisementBanner] = useState([]);
  const [advertisementVideo, setAdvertisementVideo] = useState([]);
  const [modalAdvertisementImage, setModalAdvertisementImage] = useState(null);
  const [modalAdvertisementVideo, setModalAdvertisementVideo] = useState(null);

  const fetchAdvertisement = async () => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.advertisementFilter,
      method: "get",
    });
    if (success) {
      setAdvertisementBanner(data?.banner);
      setAdvertisementVideo(data?.video);
    } else {
      showMessage(message);
    }
  };

  const handleImageClick = (imageUrl) => {
    setModalAdvertisementImage(imageUrl);
  };

  const handleVideoClick = (VideoUrl) => {
    setModalAdvertisementVideo(VideoUrl);
  };

  const handleCheck = async () => {
    try {
      const { data, success, message } = await apiRequest({
        endUrl: API_END_URLS.advertisemetCheck,
        method: "post",
      });
      if (data) {
        navigate("/Advertisement-rule");
      } else {
        showMessage(message);
        if (message) {
          navigate("/login");
        }
      }
    } catch (error) {}
  };

  /* Advertisement End Code */

  /* Blog Start Code */

  const [airBlogData, setAirBlogData] = useState({});
  const [marineBlogData, setMarineBlogData] = useState({});
  const [landBlogData, setLandBlogData] = useState({});

  const fatchCode = async () => {
    try {
      const { data, message, success } = await apiRequest({
        endUrl: API_END_URLS.getBlogs,
        method: "get",
      });
      if (success) {
        setAirBlogData(data?.getBlogAir);
        setMarineBlogData(data?.getBlogMarine);
        setLandBlogData(data?.getBlogLand);
      } else {
        showMessage(message);
      }
    } catch (error) {}
  };

  const handleRedirectBlogRealted = (id) => {
    if (id) {
      navigate(`/blog/get-one-blog/${id}`);
    } else {
      showMessage("No blog available");
    }
  };

  /* Blog End Code */

  /* User fatch data code start */

  const [user, setUser] = useState(false);

  const getUser = async (id) => {
    const { data, message, success } = await apiRequest({
      endUrl: API_END_URLS.getUserProfile,
      method: "get",
    });
    if (success) {
      setUser({ ...data });
    }
  };
  /* User fatch data code end*/
  const handleCheckSubscriptionType = (item) => {
    const checkLogin = getCredentials();
    if (!checkLogin) {
      showMessage("Please Login");
      return navigate("/login");
    }
    if (user?.user?.subscription_plan_id) {
      navigate(`/all-products/${item?.id}`);
    } else {
      showMessage("Please Subscribe Plan");
      navigate("/choose-plan");
    }
  };

  const handleDealsAndPromotions = (item) => {
    const checkLogin = getCredentials();
    if (!checkLogin) {
      showMessage("Please Login");
      return navigate("/login");
    }
    if (user?.user?.subscription_plan_id) {
      navigate(`/dealsAndPromotion-products/${item?.id}`);
      localStorage.setItem("removeFilter", true);
    } else {
      showMessage("Please Subscribe Plan");
      navigate("/choose-plan");
    }
  };

  const handleRangAndProducts = (item) => {
    const checkLogin = getCredentials();
    if (!checkLogin) {
      showMessage("Please Login");
      return navigate("/login");
    }
    if (user?.user?.subscription_plan_id) {
      navigate(`/range-products/${item?.id}`);
    } else {
      showMessage("Please Subscribe Plan");
      navigate("/choose-plan");
    }
  };

  const handleSeeMore = (item) => {
    const checkLogin = getCredentials();
    if (!checkLogin) {
      showMessage("Please Login");
      return navigate("/login");
    }
    if (user?.user?.subscription_plan_id) {
      navigate(`/products-list/${item?.category_id}`);
    } else {
      showMessage("Please Subscribe Plan");
      navigate("/choose-plan");
    }
  };

  useEffect(() => {
    fetchAdvertisement();
    getAllCategoryList();
    fatchData();
    rangeProduct();
    fetchReviewData();
    localStorage.removeItem("removeFilter");
    fatchCode();
    getUser();
  }, []);

  const handleSearch = () => {
    if (searchValue.trim() === "") {
      setSearchTerm("");
      setSearchValue("");
    } else {
      setSearchTerm(searchValue);
    }
    setTimeout(() => {
      if (firstMatchRef.current) {
        firstMatchRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);

    let firstMatchFound = false;

    return parts.map((part, index) => {
      if (part.toLowerCase() === searchTerm.toLowerCase()) {
        if (!firstMatchFound) {
          firstMatchFound = true;
          return (
            <span
              key={index}
              style={{ backgroundColor: "yellow" }}
              ref={firstMatchRef}
            >
              {part}
            </span>
          );
        }
        return (
          <span key={index} style={{ backgroundColor: "yellow" }}>
            {part}
          </span>
        );
      } else {
        return part;
      }
    });
  };

  const highlightSearchTermInHtml = (htmlString, searchTerm) => {
    if (!searchTerm || !htmlString) return htmlString;

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const walkNodes = (node) => {
      if (node.nodeType === Node.TEXT_NODE) {
        const regex = new RegExp(`(${searchTerm})`, "gi");
        const matches = node.textContent.match(regex);

        if (matches) {
          const highlightedHtml = node.textContent.replace(
            regex,
            '<span style="background-color: yellow;">$1</span>'
          );
          const spanWrapper = document.createElement("span");
          spanWrapper.innerHTML = highlightedHtml;
          node.replaceWith(spanWrapper);
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        Array.from(node.childNodes).forEach(walkNodes);
      }
    };

    walkNodes(doc.body);
    return doc.body.innerHTML;
  };

  const getBanningHeading = (type, searchTerm) => {
    let homePageData = data?.filter((item) => item?.key === type);

    let htmlString = homePageData?.length ? homePageData[0].value : "";

    return highlightSearchTermInHtml(htmlString, searchTerm);
  };

  const _HomeContent = {
    bannerHeading: _HomePages.bannerHeading,
    bannerDescription: _HomePages.bannerSubText,
    newCustomerMonthlyValue: _HomePages.newCustomerMonthlyValue,
    newCustomerMonthly: _HomePages.newCustomerMonthly,
    increaseInTheSize: _HomePages.increaseInTheSize,
    increaseInTheSizeValue: _HomePages.increaseInTheSizeValue,
    yearOfExperienceValue: _HomePages.yearOfExperienceValue,
    yearOfExperience: _HomePages.yearOfExperience,
    timeMoreRFQValue: _HomePages.timeMoreRFQValue,
    timeMoreRFQ: _HomePages.timeMoreRFQ,
    seactionHeading: _HomePages.seactionHeading,
    bannerSubTextSecond: _HomePages.bannerSubTextSecond,
  };

  const handleInputChange = (e) => {
    const value = e.target.value.trim();
    setSearchValue(value);
    setSearchTerm(value);

    if (value === "") {
      setSearchTerm("");
    }
  };

  return (
    <>
      <Header></Header>
      <section className="hero-section py-4 py-sm-5">
        <div className="container">
          <div className="row mt-sm-4">
            <div className="col-md-9">
              <div className="bannerhead-textcontent">
                <h1 className="goldman">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getBanningHeading(
                        _HomeContent.bannerHeading,
                        searchTerm
                      ),
                    }}
                  />
                </h1>
              </div>
            </div>
          </div>

          <div className="banner-content mt-3">
            <div className="row">
              <div className="col-md-6 mb-3 mb-sm-0">
                <h6>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getBanningHeading(
                        _HomeContent.bannerDescription,
                        searchTerm
                      ),
                    }}
                  />{" "}
                </h6>

                <div className="serch-partbox mt-sm-4 mt-3">
                  <div class="position-relative">
                    <label className="search-label">
                      Search In Web Page
                    </label>
                    <input
                      type="email"
                      class="form-control search-input padding-right"
                      placeholder="Part Name or description"
                      value={searchValue}
                      onChange={handleInputChange}
                    />
                    <button
                      type="button"
                      class="btn search-btn btn-position"
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="video-filed-content video-filed-content-2">
                  <iframe
                    class="embed-responsive-item"
                    src={getBanningHeading(_HomePages?.bannerVideo)}
                    title="Video"
                  ></iframe>
                </div>
              </div>
            </div>

            <div className="row mt-sm-5 mt-3">
              <div className="col-md-3 mb-2 mb-sm-0">
                <div
                  className="d-flex align-items-center"
                  onClick={() => navigate("/choose-plan")}
                  style={{ cursor: "pointer" }}
                >
                  <div className="mr-3">
                    <h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getBanningHeading(
                            _HomeContent.newCustomerMonthlyValue,
                            searchTerm
                          ),
                        }}
                      />
                    </h4>
                  </div>
                  <div>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getBanningHeading(
                            _HomeContent.newCustomerMonthly,
                            searchTerm
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-2 mb-sm-0 pl-sm-0">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getBanningHeading(
                            _HomeContent.increaseInTheSizeValue,
                            searchTerm
                          ),
                        }}
                      />
                    </h4>
                  </div>
                  <div>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getBanningHeading(
                            _HomeContent.increaseInTheSize,
                            searchTerm
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 mb-2 mb-sm-0">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getBanningHeading(
                            _HomeContent.yearOfExperienceValue,
                            searchTerm
                          ),
                        }}
                      />
                    </h4>
                  </div>
                  <div>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getBanningHeading(
                            _HomeContent.yearOfExperience,
                            searchTerm
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getBanningHeading(
                            _HomeContent.timeMoreRFQValue,
                            searchTerm
                          ),
                        }}
                      />
                    </h4>
                  </div>
                  <div>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getBanningHeading(
                            _HomeContent.timeMoreRFQ,
                            searchTerm
                          ),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 py-sm-5 sea-section mt-sm-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-3 mb-sm-0">
              <div className="sealeftimg">
                <img
                  src={getBanningHeading(_HomePages.imageResolutionOneUrl)}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                {category?.map((item, index) => {
                  return (
                    <div className="col-md-6 mb-sm-4 mb-3">
                      <div className="land-box">
                        <h4>{highlightText(item.category_name, searchTerm)}</h4>
                        <p>{highlightText(item.description, searchTerm)}</p>
                        <button
                          type="button"
                          class="btn sea-btn mt-sm-4 mt-3"
                          onClick={() => handleCheckSubscriptionType(item)}
                        >
                          See Listings
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 py-sm-5">
        <div className="container">
          <div className="row mt-sm-4">
            <div className="col-md-12">
              <div className="sectop-headcontent text-center">
                <h3
                  className="goldman"
                  onClick={() => handleCheck()}
                  style={{ cursor: "pointer" }}
                >
                  Advertise Here
                </h3>
                <span className="explore-box">
                  <img src="images/exp-img.svg" alt="" class="before-explore" />
                  <img
                    src="images/explore-img.svg"
                    alt=""
                    class="after-explore"
                    onClick={() => handleCheck()}
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="row mt-sm-5 mt-3">
            <div className="col-md-6 mb-3 mb-sm-0">
              <div className="row  experiance-slide">
                <div className="col-md-12">
                  <Slider
                    asNavFor={nav1}
                    ref={(slider1) => setNav1(slider1)}
                    {...sliderfirstsetting}
                  >
                    {advertisementBanner?.map((item) => {
                      return (
                        <div>
                          <div
                            className="advertise-img"
                            onClick={() => handleImageClick(item?.media_url)}
                            style={{ cursor: "pointer" }}
                          >
                            <img src={item?.media_url} alt="" />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row  experiance-slide">
                <div className="col-md-12">
                  <div>
                    <VideoSlider
                      videoUrls={advertisementVideo.map(
                        (video) => video.media_url
                      )}
                      onVideoClick={handleVideoClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 py-sm-5 mt-sm-4 customers-section">
        <div className="container-fluid pl-sm-0">
          <div className="row">
            <div className="col-md-5 pr-sm-0 mb-sm-0 mb-4">
              <div className="advancedinnerimg">
                <img
                  src={getBanningHeading(_HomePages.imageResolutionTwoUrl)}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-7 pl-sm-0">
              <div className="customer-text pb-sm-4">
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <h2> // Why we are Best</h2>
                    <h6 className="goldman">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: getBanningHeading(
                            _HomeContent.seactionHeading,
                            searchTerm
                          ),
                        }}
                      />
                    </h6>
                    <div style={{ Width: "100px", color: "red" }}>
                      <h5>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: getBanningHeading(
                              _HomeContent.bannerSubTextSecond,
                              searchTerm
                            ),
                          }}
                        />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-sm-5 mt-3 align-items-center">
                <div className="col-md-5 mb-3 mb-sm-0  pl-sm-0">
                  <div className="satisfied-customers text-center">
                    <h6>
                      {getBanningHeading(_HomePages.satisfiedCustomerValue)}
                    </h6>
                    <p> {getBanningHeading(_HomePages.satisfiedCustomer)} </p>
                  </div>
                </div>
                <div className="col-md-6 pb-sm-0 pb-5">
                  <div className="satisfied-content mb-3 mb-sm-0">
                    <h4>
                      {" "}
                      <i class="fa fa-check-circle" aria-hidden="true"></i>{" "}
                      {getBanningHeading(_HomePages.textOne)}{" "}
                    </h4>
                    <h4>
                      {" "}
                      <i class="fa fa-check-circle" aria-hidden="true"></i>{" "}
                      {getBanningHeading(_HomePages.textTwo)}{" "}
                    </h4>
                    <h4>
                      {" "}
                      <i class="fa fa-check-circle" aria-hidden="true"></i>{" "}
                      {getBanningHeading(_HomePages.textThree)}{" "}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-md-5">
                  <div>
                    <img
                      src={getBanningHeading(
                        _HomePages.imageResolutionThreeUrl
                      )}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-sm-5 py-4 deals-promotionssec mt-sm-4">
        <div className="container">
          <div className="row pt-sm-4 pb-3">
            <div className="col-md-12">
              <div className="sectop-headcontent text-center">
                <h3 className="goldman">Deals & Promotions</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-11 pr-0">
              <div className="deals-promotionscontent my-sm-5  my-3">
                <div className="main-dealbox">
                  {category?.map((item) => {
                    return (
                      <>
                        <div className="deals-promotionshoverbox">
                          <div className="ship-imgbox">
                            <img src={item?.category_logo} alt="" />
                          </div>
                          <h2>
                            {highlightText(item?.category_name, searchTerm)}
                          </h2>
                          <h6>
                            {highlightText(item?.description, searchTerm)}
                          </h6>
                          <span className="arrow-box">
                            <img
                              src="images/arrow-up.svg"
                              alt=""
                              onClick={() => {
                                handleDealsAndPromotions(item);
                              }}
                            />
                          </span>
                          <div
                            class="overlay-left-slide"
                            onClick={() => {
                              handleDealsAndPromotions(item);
                            }}
                          >
                            <p>New Product</p>
                            <p>Used Product</p>
                            <p>Lease & Rental</p>
                            <p>Services</p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-sm-5 py-4">
        <div className="container">
          <div className="row pt-sm-4">
            <div className="col-md-12">
              <div className="sectop-headcontent text-center">
                <h3 className="goldman">Range Of Products</h3>
              </div>
            </div>
          </div>

          <div className="row mt-sm-5 mt-2">
            {rangeOfProduct?.map((item) => {
              return (
                <div className="col-md-4 position-relative mb-sm-0 mb-3">
                  <h6>
                    {highlightText(item?.Category?.category_name, searchTerm)}
                  </h6>
                  <div className="range-productbox">
                    {item?.product_promotion === 1 && (
                      <span className="promoted-product-range">
                        <img
                          src="/images/reward.svg"
                          className="reward-img"
                          alt="/"
                        />
                        Promoted Product
                      </span>
                    )}
                    <div className="range-productimgbox mt-sm-4 mt-2">
                      <img src={item?.ProductImages[0]?.image_url} alt="" />
                    </div>

                    <div className="range-productcontentbox">
                      <h2 className="goldman">{item?.brand}</h2>
                      <p>{item?.description}</p>

                      <div className="row mt-sm-4 mt-3 align-items-center">
                        <div className="col-md-7 col-6">
                          <button
                            type="button"
                            class="btn view-detailsbtn"
                            onClick={() => handleRangAndProducts(item)}
                          >
                            View Details
                          </button>
                        </div>
                        <div className="col-md-5 col-6 text-right">
                          <h3 onClick={() => handleSeeMore(item)}>
                            See More{" "}
                            <img src="images/arrow-greenup.svg" alt="" />{" "}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="plain-section pt-4 pt-sm-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="why-sec">
                <h2 className="goldman">
                  {highlightText(
                    "Why NavigatorsRus",
                    searchTerm,
                    firstMatchRef
                  )}
                </h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-end mt-sm-4 mt-2 align-items-center">
            <div className="col-md-4">
              <p className="online-text">
                {highlightText(
                  "An online portal to list your products & services applied in marine, aviation & land based needs to sell or rent or lease",
                  searchTerm,
                  firstMatchRef
                )}
              </p>
            </div>
            <div className="col-md-5 text-right pl-sm-0 pt-3">
              <img
                src="images/black-plain.png"
                className="black-whyright"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="row  h-100">
                <div className="col-md-6 col-6 pr-0">
                  <div className="parts-imgbox">
                    <img src="images/ship-right.png" alt="" />
                  </div>
                </div>
                <div className="col-md-6 col-6 pr-sm-0 pl-0">
                  <div className="parts-textcontent">
                    <h3 className="goldman">{highlightText("Used & New Parts", searchTerm, firstMatchRef)}</h3>
                    {highlightText(
                      "Our portal enables subscribers to market both new and used products by displaying the details enabling other subscribers or buyers to view and buy",
                      searchTerm,
                      firstMatchRef
                    )}
                  </div>
                </div>

                <div className="col-md-6 col-6 pr-0">
                  <div className="verified-seller">
                    <h3 className="goldman"> {highlightText("Verified Seller", searchTerm, firstMatchRef)}</h3>
                    <h6>
                    {highlightText(
                      "We strongly support best practice sharing across our operations and ensure that our subscribers are genuine and reliable",
                      searchTerm,
                      firstMatchRef
                    )}
                    </h6>
                  </div>
                </div>
                <div className="col-md-6 col-6 pl-0 pr-sm-0">
                  <div className="parts-imgbox">
                    <img src="images/plain.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 pl-sm-0">
              <img src="images/right-img.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="subscribe-annuallysec py-sm-5 py-4">
        <div className="container">
          <div className="row pt-sm-4">
            <div className="col-md-12">
              <div className="sectop-headcontent text-center">
                <h3 className="goldman">
                {highlightText("Subscribe Annually & Save More", searchTerm, firstMatchRef)}
                </h3>
              </div>
            </div>
          </div>

          <div className="row justify-content-center mt-sm-4 mt-2 mb-sm-5 mb-4">
            <div className="col-md-9">
              <div className="row justify-content-between">
                <div className="col-md-6 mb-4 mb-sm-0">
                  <div className="top-bannerimg position-relative">
                    <img src="images/standard.png" alt="" />
                    <h2 className="goldman"> {highlightText("Standard Plan", searchTerm, firstMatchRef)}</h2>
                  </div>
                  <div className="standard-plan">
                    <div className="inner-standardplan">
                    <p>{highlightText("Monthly Subscription (In AED)", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("5", searchTerm, firstMatchRef)}</h6>
                    </div>
                    <div className="inner-standardplan">
                    <p>{highlightText("Yearly Subscription (In AED)", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("50", searchTerm, firstMatchRef)}</h6>
                    </div>
                    <div className="inner-standardplan">
                    <p>{highlightText("Posting Per Year", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("24", searchTerm, firstMatchRef)}</h6>
                    </div>
                    <div className="inner-standardplan">
                    <p>{highlightText("Photo Uploads Per Year", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("48", searchTerm, firstMatchRef)}</h6>
                    </div>
                    <div className="inner-standardplan">
                    <p>{highlightText("Posting Display Duration (In Days)", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("60", searchTerm, firstMatchRef)} </h6>
                    </div>

                    <div className="inner-standardplan">
                    <p>{highlightText("Promotions Per year", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("24", searchTerm, firstMatchRef)}</h6>
                    </div>
                    <div className="inner-standardplan mb-sm-5">
                    <p>{highlightText("Promotions Display Duration (In Days)", searchTerm, firstMatchRef)}</p>
                      <h6> {highlightText("60", searchTerm, firstMatchRef)} </h6>
                    </div>

                    <button
                      type="button"
                      class="btn standard-btn btn-block mt-4"
                      onClick={() => navigate("/choose-plan")}
                    >
                        {highlightText("Choose Basic", searchTerm, firstMatchRef)}
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="top-bannerimg position-relative">
                    <img src="images/primi.png" alt="" />
                    <h2 className="goldman"> {highlightText("Premium Plan", searchTerm, firstMatchRef)}</h2>
                  </div>
                  <div className="standard-plan premium-plan">
                    <div className="inner-standardplan">
                    <p>{highlightText("Monthly Subscription (In AED)", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("10", searchTerm, firstMatchRef)}</h6>
                    </div>
                    <div className="inner-standardplan">
                    <p>{highlightText("Yearly Subscription (In AED)", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("100", searchTerm, firstMatchRef)}</h6>
                    </div>
                    <div className="inner-standardplan">
                    <p>{highlightText("Posting Per Year", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("48", searchTerm, firstMatchRef)}</h6>
                    </div>
                    <div className="inner-standardplan">
                    <p>{highlightText("Photo Uploads Per Year", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("240", searchTerm, firstMatchRef)}</h6>
                    </div>
                    <div className="inner-standardplan">
                    <p>{highlightText("Posting Display Duration (In Days)", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("90", searchTerm, firstMatchRef)} </h6>
                    </div>

                    <div className="inner-standardplan">
                    <p>{highlightText("Promotions Per year", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("48", searchTerm, firstMatchRef)}</h6>
                    </div>
                    <div className="inner-standardplan mb-sm-5">
                    <p>{highlightText("Promotions Display Duration (In Days)", searchTerm, firstMatchRef)}</p>
                      <h6>{highlightText("90", searchTerm, firstMatchRef)} </h6>
                    </div>

                    <button
                      type="button"
                      class="btn premiumplan-btn btn-block mt-sm-4"
                      onClick={() => navigate("/choose-plan")}
                    >
                       {highlightText("Choose Advance", searchTerm, firstMatchRef)}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-4 py-sm-5">
        <div className="container">
          <div className="row pt-sm-4">
            <div className="col-md-12">
              <div className="sectop-headcontent text-center">
                <p>  
                  {highlightText(
                    "Our latest Blog",
                    searchTerm,
                    firstMatchRef
                  )} 
                  </p>
                <h3 className="goldman"> 
                {highlightText(
                    "Blog & Insights",
                    searchTerm,
                    firstMatchRef
                  )} 
                 </h3>
              </div>
            </div>
          </div>
          <div className="row mt-sm-5 mt-3">
            <div className="col-md-4 mb-3 mb-sm-0">
              <div className="card-box">
                <div className="blog-imgbox">
                  <img
                    src={
                      marineBlogData?.blog_image
                        ? S3Bucket + marineBlogData?.blog_image
                        : "images/blog-right2.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-body position-relative">
                  <div className="box-newplan">
                    <div className="green"></div>
                    <div className="black">
                      {_BlogCategory[marineBlogData?.CMSBlogType?.blog_type]}
                    </div>
                  </div>

                  <p>{marineBlogData?.blog_title}</p>
                  <h3>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: marineBlogData?.blog_description,
                      }}
                    />
                  </h3>

                  <button
                    type="button"
                    class="btn view-detailsbtn mt-sm-4 mt-3 mb-sm-3"
                    onClick={() =>
                      handleRedirectBlogRealted(marineBlogData?.id)
                    }
                  >
                    {" "}
                    Read More
                    <img src="/images/arrow-up.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-3 mb-sm-0">
              <div className="card-box">
                <div className="blog-imgbox">
                  <img
                    src={
                      airBlogData?.blog_image
                        ? S3Bucket + airBlogData?.blog_image
                        : "images/blog-right2.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-body position-relative">
                  <div className="box-newplan">
                    <div className="green"></div>
                    <div className="black">
                      {_BlogCategory[airBlogData?.CMSBlogType?.blog_type]}
                    </div>
                  </div>
                  <p>{airBlogData?.blog_title}</p>
                  <h3>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: airBlogData?.blog_description,
                      }}
                    />
                  </h3>
                  <button
                    type="button"
                    class="btn view-detailsbtn mt-sm-4 mt-3 mb-sm-3"
                    onClick={() => handleRedirectBlogRealted(airBlogData?.id)}
                  >
                    {" "}
                    Read More
                    <img src="/images/arrow-up.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card-box">
                <div className="blog-imgbox">
                  <img
                    src={
                      landBlogData?.blog_image
                        ? S3Bucket + landBlogData?.blog_image
                        : "images/blog-right2.png"
                    }
                    alt=""
                  />
                </div>
                <div className="card-body position-relative">
                  <div className="box-newplan">
                    <div className="green"></div>
                    <div className="black">
                      {_BlogCategory[landBlogData?.CMSBlogType?.blog_type]}
                    </div>
                  </div>
                  <p>{landBlogData?.blog_title}</p>
                  <h3>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: landBlogData?.blog_description,
                      }}
                    />
                  </h3>
                  <button
                    type="button"
                    class="btn view-detailsbtn mt-sm-4 mt-3 mb-sm-3"
                    onClick={() => handleRedirectBlogRealted(landBlogData?.id)}
                  >
                    {" "}
                    Read More
                    <img src="/images/arrow-up.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-sec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="testimonial-box">
                <div className="sectop-headcontent text-center">
                  <h6> {highlightText("Testimonial", searchTerm, firstMatchRef)} </h6>
                  <h3 className="goldman">{highlightText("What Our Client Say", searchTerm, firstMatchRef)}</h3>
                </div>
                <div className="row justify-content-center mt-sm-5 mt-4">
                  <div className="col-md-11">
                    <div className="textmonial-slider">
                      <div className="row my-5">
                        <div className="col-md-12">
                          <Slider
                            asNavFor={nav2}
                            ref={(slider1) => setNav1(slider1)}
                            {...sliderfirstsetting2}
                          >
                            {reviewData?.map((item) => {
                              return (
                                <div className="testimonial-contentbox text-center">
                                  <div className="testimonial-userpic">
                                    <img
                                      src={S3Bucket + item?.User?.profile_url}
                                      alt=""
                                    />{" "}
                                  </div>
                                  <h2>{item?.User?.name}</h2>
                                  <img
                                    className="text-center"
                                    src="/images/quote.svg"
                                    alt=""
                                  />
                                  <p>{item?.review_description}.</p>
                                </div>
                              );
                            })}
                          </Slider>
                        </div>

                        {/* <div className="col-md-12 mb-5 mb-sm-0">
                          <div className="main-dealbox">
                            <Slider
                              asNavFor={nav1}
                              ref={(slider1) => setNav1(slider1)}
                              {...sliderfirstsetting}
                            >
                              {reviewData?.map((item) => {
                                return (
                                  <div className="testimonial-contentbox text-center">
                                    <div className="testimonial-userpic">
                                      <img
                                        src={S3Bucket + item?.User?.profile_url}
                                        alt=""
                                      />{" "}
                                    </div>
                                    <h2>{item?.User?.name}</h2>
                                    <img
                                      className="text-center"
                                      src="/images/quote.svg"
                                      alt=""
                                    />
                                    <p>{item?.review_description}.</p>
                                  </div>
                                );
                              })}
                            </Slider>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-sm-4 py-3 py-sm-0">
            <div className=" col-md-12 text-center">
              <button
                type="button"
                class="btn review-btn"
                onClick={() => navigate("/review")}
              >
                {highlightText("Review NavigatorsRus", searchTerm, firstMatchRef)}
               
              </button>
            </div>
          </div>
        </div>
      </section>

      <FooterBottam></FooterBottam>

      <Modal
        show={modalAdvertisementVideo !== null}
        onHide={() => setModalAdvertisementVideo(null)}
        className="advertise-heremodal"
        size="lg"
      >
        <Modal.Body>
          <div class="row text-center">
            <div class="col-md-12">
              <div class="slider-popupimg">
                <video width="100%" height="560" controls>
                  <source src={modalAdvertisementVideo} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={modalAdvertisementImage !== null}
        onHide={() => setModalAdvertisementImage(null)}
        className="advertise-heremodal"
        size="lg"
      >
        <Modal.Body>
          <div className="row text-center">
            <div className="col-md-12">
              <div className="slider-popupimg">
                <img src={modalAdvertisementImage} alt="" />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer></Footer>
    </>
  );
}

export default HomeNew;
